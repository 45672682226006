/*global EaseScroll, lazyload*/

'use strict';

import { on, off, throttle, isInViewport } from './utils/_fn';
import './modules/cookie-alert';
import { UAParser } from 'ua-parser-js';

/* ---------------------------------------- [START] Browser Tip */
(function() {
	window.UAParser = UAParser;
	const uap = new UAParser();
	const uapResult = uap.getResult();
	if (uapResult.browser.name.includes('IE') ||
		(uapResult.browser.name.includes('Chrome') && +(uapResult.browser.version.split('.')[0] < 85)) ||
		(uapResult.browser.name.includes('Firefox') && +(uapResult.browser.version.split('.')[0] < 75)) ||
		(uapResult.browser.name === 'Edge' && +(uapResult.browser.version.split('.')[0] < 80)) ||
		(uapResult.browser.name.includes('Safari') && +(uapResult.browser.version.split('.')[0] < 13)) ||
		(uapResult.os.name === 'iOS' && +(uapResult.os.version.split('.')[0] < 13)) ||
		(uapResult.os.name === 'Android' && +(uapResult.os.version.split('.')[0] < 5))
	) {
		// Chrome 手機模擬器 Android 是 6.0 不能擋以上的版本
		location.href = 'browser.html';
	}
})();
/* ---------------------------------------- [END] Browser Tip */

(function (window, document) {
	/* ---------------------------------------- [START] Windows Setting */
	const html = document.documentElement;
	const body = document.body;
	let ww = window.innerWidth;
	let wh = window.innerHeight;
	let ws = 0;
	function getScrollTop(target = window) {
		return (target.pageYOffset || html.scrollTop) - (html.clientTop || 0);
	}
	function getWinSet() {
		ww = window.innerWidth;
		wh = window.innerHeight;
		ws = getScrollTop();
	}
	on(window, 'load', getWinSet);
	on(window, 'resize', throttle(getWinSet, 50, 100));
	/* ---------------------------------------- [END] Windows Setting */

	/* ---------------------------------------- [START] 判斷 Browser + Device (使用 ua-parse-js) */
	let isSmartDevice = false; // 是否是智慧型裝置
	let isMobile = false; // 是否是手機（無法跟平板同時）
	let isTablet = false; // 是否是平板（無法跟手機同時）
	function deviceBrowserCheck() {
		const uap = new UAParser();
		const uapResult = uap.getResult();
		html.classList.add(uapResult.browser.name.toLocaleLowerCase().replace(/\s/g, '-'));
		html.classList.add(uapResult.os.name.toLocaleLowerCase().replace(/\s/g, '-'));

		// IsMobile
		isMobile = uapResult.device.type === 'mobile';
		if (isMobile) {
			html.classList.add('is-mobile');
		} else {
			html.classList.remove('is-mobile');
		}

		// IsTable
		isTablet = uapResult.device.type === 'tablet';
		if (isTablet) {
			html.classList.add('is-tablet');
		} else {
			html.classList.remove('is-tablet');
		}

		isSmartDevice = isMobile || isTablet;
	}

	// 防止測試時一直用開發者工具Resize出現Bug
	on(window, 'resize', throttle(deviceBrowserCheck, 50, 100));
	on(window, 'load', deviceBrowserCheck);
	/* ---------------------------------------- [END] 判斷 Browser + Device (使用 ua-parse-js) */

	/* ----------------------------------- [START] Loader 移除 */
	var loaderRemove = function () {
		var loader = document.querySelector('#loader');
		window.loader = loader; // 加到 window 上

		pageLoaded();
	};
	on(window, 'load', loaderRemove);

	/* 頁面可呼叫的 function -------- */
	/* 開啟 Loading */
	window.pageLoading = function() {
		loader.classList.remove('is-loaded');
		document.body.appendChild(loader);
	}

	/* 關閉 Loading */
	window.pageLoaded = function() {
		loader.classList.add('is-loaded');
		setTimeout(function () {
			loader.remove();
		}, 2000);
	}
	/* ----------------------------------- [END] Loader 移除 */

	/* ---------------------------------------- [START] Lazyload */
	/*
	 * 使用：https://github.com/verlok/vanilla-lazyload
	 * 尋找頁面上的 .lazy 為執行 Lazy Load 物件
	 */
	var lazyloadTimer = 0;
	function buildLazyLoad() {
		if (lazyloadTimer < 5 && window.LazyLoad === undefined) {
			return setTimeout(function () {
				lazyloadTimer++;
				buildLazyLoad();
			}, 500);
		}

		var lazyLoadInstance = new LazyLoad({
			// Your custom settings go here
		});
	}
	on(window, 'load', buildLazyLoad);
	/* ---------------------------------------- [END] Lazyload */

	/* ---------------------------------------- [START] Foundation */
	/* [START] OffCanvas - Menu Close */
	function initAfterOffCanvas() {
		var hamburgerMenuClassIsActive = 'is-menu-open';
		var hamburgerMenu = document.querySelector('#hd-ham');
		$(document).on('opened.zf.offCanvas', '[data-off-canvas]', function () {
			hamburgerMenu.classList.add(hamburgerMenuClassIsActive);
		});

		$(document).on('close.zf.offCanvas', '[data-off-canvas]', function () {
			hamburgerMenu.classList.remove(hamburgerMenuClassIsActive);
		});
	}
	/* [END] OffCanvas - Menu Close */

	on(window, 'load', function () {
		// 啟動程式碼 $(document).foundation(); 寫於src\js\vendor\foundation.6.7.5.custom.js
		// After Init
		initAfterOffCanvas(); // Menu - Close
	});
	/* ---------------------------------------- [END] Foundation */
	
	/* ---------------------------------------- [START] Header Main Menu */
	function initHdMenu() {
		// Hover 到子選單執行反映
		const isOpenClassname = 'menu-sub-open';
		document.querySelectorAll('.main-menu__item.has-sub').forEach((menuItem) => {
			menuItem.addEventListener('mouseenter', function() {
				if (ww > 1024) {
					html.classList.add(isOpenClassname);
				}
			});

			menuItem.addEventListener('mouseleave', function() {
				html.classList.remove(isOpenClassname);
			});
		});

		// 設定選單為螢幕高度
		const hdMenuEl = document.getElementById('hd-menu');
		let whCache = wh;
		const setMenuHeight = function() {
			if (wh === whCache) {
				return;
			}
			whCache = wh;
			hdMenuEl.style.height = wh + 'px';
		}
		const setMenuHeightThrottle = throttle(setMenuHeight, 50, 100);
		const breakpoint = window.matchMedia('(min-width: 1024px)');
		const breakpointChecker = function() {
			if (breakpoint.matches) {
				// Large
				off(window, 'resize', setMenuHeightThrottle);
				hdMenuEl.style.height = '';
			} else {
				// Medium + Small
				setMenuHeightThrottle();
				on(window, 'resize', setMenuHeightThrottle);
			}
		}
		// Start
		breakpointChecker();
		breakpoint.addListener(breakpointChecker);
	}

	on(window, 'load', initHdMenu);
	/* ---------------------------------------- [END] Header Main Menu */

	/* ---------------------------------------- [START] 選單項目開啟關閉 */
	/* 選單項目開啟關閉(Header Menu, Footer Sitemap) */
	function ToggleMenu(el, ops) {
		var toggleMenuEl = document.querySelectorAll(el);
		const { disableSize, mutliExpand = true, callback } = ops;

		if (toggleMenuEl === undefined || toggleMenuEl === null || toggleMenuEl.length <= 0) return false;

		[].forEach.call(toggleMenuEl, function (el) {
			el.menu = {
				el: el,
				parent: el.parentElement,
				subLink: el.nextElementSibling ? el.nextElementSibling.querySelectorAll('a') : null,
				classOpen: 'is-open',
				classTl3d: 'translate3d',
				timer: null,
			};

			if (!el.menu.subLink) return false;

			on(el, 'click', function (event) {
				if (ww < disableSize && !el.classList.contains('is-native')) {
					// 停止原生事件(避免有連結的會跳轉)
					event.preventDefault();

					var selfMenu = this.menu;

					// Blur
					this.blur();

					// 開啟瀏覽器加速效能(transform3d(0,0,0))
					selfMenu.subLink.forEach((item) => {
						item.classList.add(selfMenu.classTl3d);
					});

					// Toggle open Class
					selfMenu.parent.classList.toggle(selfMenu.classOpen);

					// 清除transform3d
					if (selfMenu.timer !== null) clearTimeout(selfMenu.timer);
					selfMenu.timer = setTimeout(function () {
						selfMenu.subLink.forEach((item) => {
							item.classList.remove(selfMenu.classTl3d);
						});
					}, 1000);

					// 是否可以多重展開
					if (!mutliExpand && !this.parentElement.classList.contains(classOpen)) {
						/* 關閉 => 移除內部已開啟的物件 */
						this.parentElement.querySelectorAll('.' + selfMenu.classOpen).forEach((el) => {
							el.classList.remove(selfMenu.classOpen);
						});
					}

					if (typeof callback === 'function') {
						callback.call(this);
					}
				}
			});
		});
	}

	on(window, 'load', function () {
		/* Header Menu Open Second */
		var hdMenuLink = new ToggleMenu('.main-menu__link', {
			disableSize: 1024,
		});

		/* PC 點選第一層按鈕 => Blur() */
		document.querySelectorAll('.main-menu__link').forEach(function (el) {
			if (!isMobile && ww >= 1024) {
				el.blur();
			}
		});

		/* Language(Mobile) */
		var hdMenuLink = new ToggleMenu('#menu-lang > li > a.selector', {
			disableSize: 1024,
		});

		/* Footer Sitemap */
		var ftSitemapMenu = new ToggleMenu('.ft-sitemap__title', {
			disableSize: 640,
		});
	});
	/* ---------------------------------------- [END] 選單項目開啟關閉 */

	/* ---------------------------------------- [START] 選單下滑更改樣式 */
	// const doc = document.documentElement;

	const header = document.querySelector('#header');
	const headerClassScroll = 'is-collapse';
	const headerClassScrollDown = 'is-scroll-down';
	const headerClassScrollUp = 'is-scroll-up';

	let windowScrollTopCache = getScrollTop();
	let windowScrollStatus = null;

	/**
	 * 更改向上滑動與向下滑動狀態
	 * @param {string} dir 滑動方向，輸入['down'|'up']
	 */
	function scrollStatusChange(dir) {
		if (windowScrollStatus === dir) {
			return false;
		} else {
			if (dir === 'down') {
				scrollStatusDown(header);
				scrollStatusDown(html);
			} else {
				scrollStatusUp(header);
				scrollStatusUp(html);
			}
			windowScrollStatus = dir;
		}
	}

	function scrollStatusDown(el) {
		el.classList.add(headerClassScrollDown);
		el.classList.remove(headerClassScrollUp);
	}

	function scrollStatusUp(el) {
		el.classList.remove(headerClassScrollDown);
		el.classList.add(headerClassScrollUp);
	}

	/* 滑動主要Function */
	function headerScrollHandler() {
		ws = getScrollTop();

		// 確認上滑與下滑狀態
		if (ws > windowScrollTopCache) {
			scrollStatusChange('down');
		} else if (ws !== windowScrollTopCache) {
			scrollStatusChange('up');
		}
		windowScrollTopCache = ws;

		// 下滑超過一定高度出現樣式：更改選單樣式、GoTop隱藏出現
		if (ws >= 200) {
			header.classList.add(headerClassScroll);
		} else {
			header.classList.remove(headerClassScroll);
		}
	}
	on(window, 'load', headerScrollHandler);
	on(window, 'scroll', headerScrollHandler);
	/* ---------------------------------------- [END] 選單下滑更改樣式 */

	/* ---------------------------------------- [START] 右下快速服務 */
	function initFtBottomSticky() {
		const stickyTarget = document.getElementById('fast-ser');
		// const ftBottomRightJQ = document.querySelector('.ft-bottom__r');
		const ftBottomRightJQ = $('.ft-bottom__r');
		let offsetBottom = 0;
		let bottom = 0;


		const breakpoint = window.matchMedia('(min-width: 640px)');

		const getPosTargetOffsetBottom = function () {
			return ftBottomRightJQ.offset().top + ftBottomRightJQ.height() / 2 + $('#fast-ser').height() / 2;
		}

		const setPosBottom = function () {
			offsetBottom = getPosTargetOffsetBottom();
			bottom = $('body').height() - offsetBottom;
			$('#fast-ser').css('--bottom', `${Math.round(bottom)}px`);
		}

		const scrollEvent = function() {
			if (!breakpoint.matches && (ws + wh > getPosTargetOffsetBottom() + 30) ) {
				stickyTarget.classList.remove('is-fixed');
				return;
			}
			stickyTarget.classList.add('is-fixed');
		}

		const resizeEvent = function() {
			if (breakpoint.matches) {
				return;
			}
			setPosBottom();
		}

		setTimeout(() => {
			setPosBottom();
			on(window, 'resize', throttle(resizeEvent, 50, 100));
			on(window, 'scroll', throttle(scrollEvent, 10, 50));
		}, 500);
	}
	on(window, 'load', initFtBottomSticky);
	/* ---------------------------------------- [END] 右下快速服務 */

	// ------------------------------- [START] GoTop
	/* Go Top Click */
	var goTop = $('#go-top');
	goTop.on('click touchstart', function (e) {
		e.preventDefault();
		$('html, body')
			.stop()
			.animate(
				{
					scrollTop: 0,
				},
				400,
				'swing',
				function () {
					goTop.blur();
					document.querySelector('a').focus();
					document.querySelector('a').blur();
				}
			);
	});
	// ------------------------------- [END] GoTop

	/* ----------------------------------- [START] ScrollTriggerAni */
	// 有滑動到該區增加動態者增加class "js-ani" ， 滑動到該區增加class "is-animated"
	// 用套件也好，換其它習慣方式也罷，請達成↑目的
	let aniSec = document.querySelectorAll('.js-ani');
	const scrollTriggerAniThrottle = throttle(scrollTriggerAni, 200, 500); // 節流作用

	function scrollTriggerAni() {
		for (var i = 0; i < aniSec.length; i++) {
			if (isInViewport(aniSec[i])) {
				aniSec[i].classList.add('is-animated');
			}
		}

		cleanTrigger();

		/* If load all of the item, stop Listener */
		if (aniSec.length === 0) {
			off(window, 'scroll', scrollTriggerAniThrottle);
		}
	}

	function cleanTrigger() {
		aniSec = Array.prototype.filter.call(aniSec, function (ani) {
			return !ani.classList.contains('is-animated');
		});
	}

	on(window, 'load', scrollTriggerAni);
	on(window, 'scroll', scrollTriggerAniThrottle);
	/* ----------------------------------- [END] ScrollTriggerAni */

	/* ----------------------------------- [START] Header - Global Search */
	function initHdSearch() {
		const searchInputBox = document.getElementById('hd-search-box');
		const searchInput = searchInputBox.querySelector('.hd-search__input');
		const hasValueClassName = 'has-value';
		let hasValue = false;
		on(searchInput, 'keydown', function() {
			console.log('searchInput change')
			if (!!this.value) {
				if (hasValue) {
					return;
				}
				hasValue = true;
				searchInputBox.classList.add(hasValueClassName);
			} else {
				if (!hasValue) {
					return;
				}
				hasValue = false;
				searchInputBox.classList.remove(hasValueClassName);
			}
		});
	}
	on(window, 'load', initHdSearch);
	/* ----------------------------------- [END] Header - Global Search */

	/* ----------------------------------- [START] Like Btn */
	function initBtnLike() {
		document.querySelectorAll('.js-add-like').forEach((el) => {
			on(el, 'click', function() {
				this.classList.toggle('is-active');
				this.blur();
			});
		})
	}

	on(window, 'load', initBtnLike);
	/* ----------------------------------- [END] Like Btn */

	/* ----------------------------------- [START]登入彈窗，密碼設定 */
	function togglePassword() {
		// $("#togglePassword").click(function () {
		// 	$(this).toggleClass("be-icon-eye-show be-icon-eye-hide");
		// 	var type = $(this).hasClass("be-icon-eye-hide") ? "text" : "password";
		// 	$("#password").attr("type", type);
		// });
		// 當按鈕被點擊時
		$('.toggle-password').click(function() {
			// 找到相對應的密碼欄位
			var passwordField = $(this).prev('.password-field');
			// 找到相對應的圖示元素
			var iconElement = $(this).find('i');

			// 切換密碼欄位的 type 屬性
			if (passwordField.attr('type') === 'password') {
				passwordField.attr('type', 'text');
				iconElement.removeClass('be-icon-eye-show').addClass('be-icon-eye-hide');
			} else {
				passwordField.attr('type', 'password');
				iconElement.removeClass('be-icon-eye-hide').addClass('be-icon-eye-show');
			}
		});
	}

	on(window, 'load', togglePassword);
	/* ----------------------------------- [END]登入彈窗，密碼設定 */
})(window, document);
