
/* ---------------------------------------- [START] Cookie 通知 */
(function (window, document) {
	var cookieNotice = document.querySelector('#cookie-notice');
	var cookieMaxAge; // 紀錄時效
	var setName = 'cookieNoticeHide';

	function checkCookie() {
		/* 頁面上沒有 Cookie 物件 => 不執行 */
		if (!cookieNotice) {
			return false;
		}

		var cookieNoticeBtn = cookieNotice.querySelector('button');
		var cookieNoticeBtnClose = cookieNotice.querySelector('[data-close]');

		/* 確認是否已經有確認Cookie 或 曾經按過叉（2022/07/27新增） */
		if (getCookie(setName) === 'true' || localStorage.getItem('cookieClosed') === 'true') {
			/* 已有確認 */
			cookieNotice.remove();
		} else {
			/* 未確認 */
			cookieNotice.classList.remove('is-hide');

			/* 取得過期時間設定 */
			cookieMaxAge = cookieNotice.getAttribute('data-max-age') || 180;
			if (typeof cookieMaxAge === 'string') {
				cookieMaxAge = parseInt(cookieMaxAge);
			}
		}

		var closeHandler = function() {
			cookieNotice.classList.add('to-hide');
			setTimeout(function () {
				cookieNotice.remove();
			}, 1000);
		}

		/* 按下「確認」功能 */
		cookieNoticeBtn.addEventListener('click', function () {
			setCookie(setName, true, cookieMaxAge); // 時效設定單位：天
			closeHandler();
		});

		// /* 按下「有連結的按鈕」功能 */
		// if (cookieNoticeBtnAnchor) {
		// 	cookieNoticeBtnAnchor.addEventListener('click', function() {
		// 		closeHandler();
		// 	});
		// }

		/* 按下「關閉」功能 */
		if (cookieNoticeBtnClose) {
			cookieNoticeBtnClose.addEventListener('click', function() {
				closeHandler();

				// 2022/07/27 客戶希望增加設定，按下叉後，永遠不顯示
				localStorage.setItem('cookieClosed', true);
			});
		}
	}

	window.addEventListener('load', checkCookie);

	/* ------------------------------------------------------- Tool */
	/* https://stackoverflow.com/a/24103596/11240898 */
	function setCookie(name, value, days) {
		var expires = '';
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + (value || '') + expires + '; path=/';
	}

	function getCookie(name) {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i].trim();
			if (c.indexOf(nameEQ) === 0) {
				return c.replace(nameEQ, '');
			}
		}
		return null;
	}
})(window, document);
/* ---------------------------------------- [END] Cookie 通知 */
